import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { defaultLogo, defaultColor, defaultName } from '../../shared/config/global.variables';
var UserService = /** @class */ (function () {
    function UserService() {
        this.userRoleSubject = new BehaviorSubject(this.roles);
        this.userRole = this.userRoleSubject.asObservable().pipe(distinctUntilChanged());
    }
    Object.defineProperty(UserService.prototype, "roles", {
        get: function () {
            if (!this.me || !this.me.roles || !this.me.roles.data) {
                return [];
            }
            var roles = this.me.roles.data.map(function (el) {
                return el.name.toLowerCase();
            });
            return roles;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "headerData", {
        get: function () {
            var me = this.me;
            if (me && me.UserSubGroups && me.UserSubGroups.data && me.UserSubGroups.data.color) {
                return me.UserSubGroups.data;
            }
            return {
                color: defaultColor,
                logo: defaultLogo,
                subgroup_name: defaultName
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserService.prototype, "me", {
        get: function () {
            var me = localStorage.getItem('me');
            if (!me || me === 'undefined') {
                return null;
            }
            return JSON.parse(me);
        },
        enumerable: true,
        configurable: true
    });
    UserService.prototype.setMe = function (me) {
        if (me === void 0) { me = null; }
        if (!me) {
            this.removeMe();
            return;
        }
        localStorage.setItem('me', JSON.stringify(me));
        this.userRoleSubject.next(this.roles);
    };
    UserService.prototype.removeMe = function () {
        localStorage.removeItem('me');
        this.userRoleSubject.next([]);
    };
    return UserService;
}());
export { UserService };
