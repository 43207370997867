export const environment = {
  production: true,
  baseUrl: 'https://api.staging.uefaresearch.com/',
  usersGuide: '../../../../assets/User_Guide_Clubs_Update_Final.pdf',
  helpLink: 'mailto:research@uefa.ch?cc=igor.masnjak@uefa.ch,&subject=Help%20request%20related%20to%20uefaresearch.com&body=Please%20write%20your%20request%20in%20short%20and%20we%20will%20get%20back%20to%20you%20shortly.%20Many%20thanks.%0D%0A%0D%0A',
  hideUBPdata: true,
  ga: null,
  termsUrl: 'terms-of-use',
  dataDescription: 'You will be able to see and use all match audience ' +
    'data of matches your club played in UEFA competitions as of season 2018/19.',
  homeTitle: 'UEFA Club Competitions STAGING',

  match_audience_data_show_email: true,
  filterDownloadClubsTeams: "Clubs"
};
