import { OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, flatMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { environment } from '../../../environments/environment';
var AuthService = /** @class */ (function () {
    function AuthService(http, router, user) {
        this.http = http;
        this.router = router;
        this.user = user;
        this.baseUrl = environment.baseUrl;
    }
    AuthService.prototype.ngOnDestroy = function () {
        if (this.logoutSubscription) {
            this.logoutSubscription.unsubscribe();
        }
    };
    AuthService.prototype.registerNewUser = function (data) {
        if (!data.role_id) {
            delete data.role_id;
        }
        if (!data.user_subgroup_id) {
            delete data.user_subgroup_id;
        }
        var url = this.baseUrl + 'auth/register';
        return this.http.post(url, data)
            .pipe(map(function (el) {
            return el.data;
        }));
    };
    AuthService.prototype.changePassword = function (data) {
        var url = this.baseUrl + 'user/change_password';
        return this.http.post(url, data);
    };
    AuthService.prototype.sendInstructions = function (data) {
        var url = this.baseUrl + 'auth/recovery';
        return this.http.post(url, data);
    };
    AuthService.prototype.resetPassword = function (data) {
        var url = this.baseUrl + 'auth/reset';
        return this.http.post(url, data);
    };
    AuthService.prototype.login = function (params) {
        var url = this.baseUrl + 'auth/login';
        return this.loginRequest(url, params);
    };
    // Login admin as user
    AuthService.prototype.loginAsUser = function (id) {
        var url = this.baseUrl + 'user/login';
        return this.loginRequest(url, { id: id });
    };
    AuthService.prototype.loginRequest = function (url, params) {
        var _this = this;
        return this.http.post(url, params)
            .pipe(flatMap(function (tokenData) {
            _this.setTokenData(tokenData);
            // now get ME object:
            return _this.getMe()
                .pipe(tap(function (meData) {
                _this.user.setMe(meData);
                return meData;
            }, function (error) {
                _this.logout(false);
                return error;
            }));
        }));
    };
    AuthService.prototype.logout = function (navigateHome, sendToApi) {
        if (navigateHome === void 0) { navigateHome = true; }
        if (sendToApi === void 0) { sendToApi = true; }
        if (sendToApi) {
            var url = this.baseUrl + 'auth/logout';
            this.logoutSubscription = this.http.post(url, {}).subscribe(function () { }); // must be before we delete token
        }
        localStorage.removeItem('token');
        this.user.setMe(null);
        if (navigateHome) {
            this.router.navigate(['/auth/login']);
        }
    };
    AuthService.prototype.setTokenData = function (tokenData) {
        localStorage.setItem('token', tokenData.token_type + ' ' + tokenData.access_token);
    };
    AuthService.prototype.acceptTerms = function () {
        var _this = this;
        var url = this.baseUrl + 'user/accept_terms';
        return this.http.put(url, null).pipe(tap(function (data) {
            // update ME object with accept_terms = 1
            var me = _this.user.me;
            me.accept_terms = 1;
            _this.user.setMe(me);
            return data;
        }, function (error) {
            return error;
        }));
    };
    Object.defineProperty(AuthService.prototype, "isLoggedIn", {
        get: function () {
            var me = this.user.me;
            if (me && localStorage.getItem('token')) {
                if (me.accept_terms === 1) { // also check terms and condition
                    return true;
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.refreshToken = function () {
        var _this = this;
        var url = this.baseUrl + 'auth/refresh';
        return this.http.post(url, {})
            .pipe(tap(function (data) {
            _this.setTokenData(data);
            return data;
        }));
    };
    AuthService.prototype.getMe = function () {
        var url = this.baseUrl + 'auth/me';
        return this.http.get(url).pipe(map(function (data) {
            return data.data;
        }));
    };
    return AuthService;
}());
export { AuthService };
