import { Routes } from '@angular/router';
import { AuthenticatedRequiredGuard } from './auth/guards/authenticated-required.guard';
// import { RoleGuard } from './auth/guards/role.guard';
import { NotAuthenticatedOnlyGuard } from './auth/guards/not-authentificated-only.guard';
var ɵ0 = function () { return import("./landing/landing.module.ngfactory").then(function (m) { return m.LandingModuleNgFactory; }); }, ɵ1 = {}, ɵ2 = function () { return import("./landing/landing.module.ngfactory").then(function (m) { return m.LandingModuleNgFactory; }); }, ɵ3 = {}, ɵ4 = function () { return import("./admin/admin.module.ngfactory").then(function (m) { return m.AdminModuleNgFactory; }); }, ɵ5 = function () { return import("./main-content/main-content.module.ngfactory").then(function (m) { return m.MainContentModuleNgFactory; }); }, ɵ6 = {};
// canActivate: [NotAuthenticatedOnlyGuard]
var routes = [
    {
        path: 'auth',
        loadChildren: ɵ0,
        data: ɵ1,
        canActivate: [NotAuthenticatedOnlyGuard] // only NOT authentificated users can visit this page
    },
    {
        path: 'recovery/:token',
        loadChildren: ɵ2,
        data: ɵ3,
        canActivate: [NotAuthenticatedOnlyGuard] // only NOT authentificated users can visit this page
    },
    // for admin & editor:
    {
        path: 'admin',
        loadChildren: ɵ4,
        canActivate: [AuthenticatedRequiredGuard] // only authentificated users can visit this pages
    },
    // for all users:
    {
        path: '',
        loadChildren: ɵ5,
        data: ɵ6,
        canActivate: [AuthenticatedRequiredGuard] // only authentificated users can visit this pages
    },
    {
        path: '**',
        redirectTo: ''
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
