/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast-messages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./toast-messages.component";
import * as i4 from "../toast.service";
var styles_ToastMessagesComponent = [i0.styles];
var RenderType_ToastMessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastMessagesComponent, data: {} });
export { RenderType_ToastMessagesComponent as RenderType_ToastMessagesComponent };
function View_ToastMessagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "aside", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [["class", "h3"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "i", [["class", "material-icons"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.dismiss(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" close "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.style; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.content; _ck(_v, 4, 0, currVal_1); }); }
function View_ToastMessagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "toast-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastMessagesComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages; var currVal_1 = _co.trackByFn; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ToastMessagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastMessagesComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.messages.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ToastMessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toast-messages", [], null, null, null, View_ToastMessagesComponent_0, RenderType_ToastMessagesComponent)), i1.ɵdid(1, 114688, null, 0, i3.ToastMessagesComponent, [i4.ToastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToastMessagesComponentNgFactory = i1.ɵccf("app-toast-messages", i3.ToastMessagesComponent, View_ToastMessagesComponent_Host_0, {}, {}, []);
export { ToastMessagesComponentNgFactory as ToastMessagesComponentNgFactory };
