import * as i0 from "@angular/core";
var Message = /** @class */ (function () {
    function Message(content, style) {
        this.content = content;
        this.style = style || 'info';
    }
    return Message;
}());
export { Message };
var ToastService = /** @class */ (function () {
    function ToastService() {
        this.messages = [];
    }
    ToastService.prototype.getMessages = function () {
        return this.messages;
    };
    // style: info&warning || danger&failed&error || success
    ToastService.prototype.showMessage = function (content, style, time) {
        var _this = this;
        if (time === void 0) { time = 3000; }
        // logic to not repeat same message multipletimes
        var isExist = false;
        this.messages.forEach(function (oldMessage) {
            if (oldMessage.content === content) {
                isExist = true;
                return;
            }
        });
        if (isExist) {
            return;
        }
        // create and show new message
        var message = new Message(content, style);
        this.messages.push(message);
        setTimeout(function () {
            _this.dismiss(message);
        }, time);
    };
    ToastService.prototype.showError = function (_a, style, time) {
        var error = _a.error, defaultMsg = _a.defaultMsg;
        if (time === void 0) { time = 3000; }
        var content = defaultMsg || 'Error, try again.';
        if (error && error.error && error.error.errors) {
            var obj = error.error.errors;
            var firstProp = obj[Object.keys(obj)[0]];
            if (firstProp) {
                content = firstProp[0];
            }
        }
        this.showMessage(content, style, time);
    };
    ToastService.prototype.dismiss = function (message) {
        var index = this.messages.indexOf(message);
        if (index > -1) {
            this.messages.splice(index, 1);
        }
    };
    ToastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ToastService_Factory() { return new ToastService(); }, token: ToastService, providedIn: "root" });
    return ToastService;
}());
export { ToastService };
